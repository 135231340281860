import {createRouter, createWebHistory, type RouteRecordRaw} from 'vue-router'

const portDetails = () => import("../components/PortDetails.vue");
const allPorts = () => import("../components/AllPorts.vue");
const contacts = () => import("../components/Contacts.vue");
const news = () => import("../components/News.vue");
const landingPage = () => import("../components/LandingPage.vue");

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: "/sadam/:id",
            name: "Port Details",
            component: portDetails
        },
        {
            path: "/sadamad",
            name: "All Ports",
            component: allPorts
        },
        {
            path: "/kontaktid",
            name: "Contacts",
            component: contacts
        },
        {
            path: "/teated/:id",
            name: "News",
            component: news
        },
        {
            path: "/",
            name: "Landing Page",
            component: landingPage
        },
        {
            path: "/taraAuth",
            name: "TARA",
            component: landingPage
        },
        {
            path: "/taraBack",
            name: "TARA back",
            component: landingPage
        }
    ]
});

export default router;
